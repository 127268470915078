<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <ComplianceHeader />
      </v-col>
      <v-col cols="12" class="pa-0" style="height: fit-content">
        <img
          src="@/assets/banner_home_hospitais.webp"
          alt="Banner Hospital Cassems Campo Grande"
          class="banner-img"
          width="100%"
          height="50%"
        >
      </v-col>
      <v-col cols="11" class="mx-auto pa-2 my-4">
        <h1>Compliance</h1>
        <p class="mt-2">
          A página de Compliance da Cassems é um espaço dedicado a promover a
          ética, a integridade e a transparência nas ações da instituição, com o
          objetivo de informar e conscientizar colaboradores, parceiros e
          beneficiários sobre as boas práticas de governança e conformidade,
          assegurando que as atividades estejam em conformidade com as leis,
          regulamentos e os valores que norteiam a Cassems.
        </p>
        <p class="mt-2">
          Neste espaço, estão disponíveis materiais educativos que reforçam o
          compromisso da Cassems com a construção de uma cultura organizacional
          fundamentada na ética e na responsabilidade social. Por meio desta
          página, a Cassems reafirma sua missão de oferecer serviços com
          excelência, sempre guiada pelo respeito às normas e pela transparência
          em suas relações.
        </p>
      </v-col>

      <v-col cols="12" class="pa-0 bg-primary text-white py-4">
        <v-col cols="11" class="mx-auto pa-2 mb-10">
          <h1>Categorias</h1>

          <div
            v-if="complianceCategories.length === 0"
            class="my-8 text-center"
          >
            <h2>Nenhuma categoria encontrada.</h2>
          </div>

          <v-row v-else class="container-card">
            <v-col
              v-for="category in complianceCategories"
              :key="'category-' + category.id"
              class="card"
            >
              <img
                :src="category.featuredImage || defaultImage"
                alt="Imagem da Categoria"
                style="border-radius: 5px"
                width="100%"
                height="180px"
              >
              <h2 class="my-2">
                {{ category.title }}
              </h2>

              <div style="flex-grow: 1" />

              <router-link
                class="custom-btn"
                :to="`${redirectToExternalPage() ? '/external' : ''}/compliance/category/${
                  category.id
                }`"
              >
                <div class="custom-btn-bg">
                  <v-icon color="#173c93" size="25px">
                    mdi-arrow-right
                  </v-icon>
                </div>
                <p class="custom-btn-text">
                  Visualizar
                </p>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col cols="12" class="pa-0">
        <ComplianceFooter />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AllCategory } from "../services/compliance-category-service";
import ComplianceFooter from "@/components/Compliance/ComplianceFooter.vue";
import ComplianceHeader from "@/components/Compliance/ComplianceHeader.vue";
import DefaultImage from "@/assets/compliance-default.webp";

export default {
  name: "ComplianceGrid",
  components: {
    ComplianceHeader: ComplianceHeader,
    ComplianceFooter: ComplianceFooter,
  },
  data: () => ({
    defaultImage: DefaultImage,
    complianceCategories: [],
  }),
  

  async created() {
    this.complianceCategories = await AllCategory();
  },

  methods: {
    redirectToExternalPage() {
      return this.$route.name && this.$route.name.startsWith("external");
    },
  },
};
</script>

<style scoped>
.banner-img {
  max-height: 70vh;
}

</style>

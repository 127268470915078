<template>
  <header class="header">
    <v-row style="justify-content: space-between">
      <v-col md="8" sm="12">
        <router-link
          :to="`${
            redirectToExternalPage() ? '/external/compliance' : '/compliance'
          }`"
        >
          <img
            class="cursor-pointer"
            src="@/assets/logo-header.webp"
            height="40px"
            alt="Logo da Cassems"
          >
        </router-link>
      </v-col>

      <v-col v-if="!redirectToExternalPage()" md="1" lg="2" class="d-flex d-sm-none" />

      <v-col v-if="!redirectToExternalPage()" lg="2" md="3" sm="12">
        <chat-ti-modal-access-vue />
      </v-col>
    </v-row>
  </header>
</template>
<script>
import ChatTiModalAccessVue from "@/components/ModalViews/ChatTiModalAccess.vue";

export default {
  name: "ComplianceHeader",
  components: {
    ChatTiModalAccessVue,
  },
  methods: {
    redirectToExternalPage() {
      return this.$route.name && this.$route.name.startsWith("external");
    },
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
  .header img {
    height: 30px;
  }
}
</style>

<template>
  <v-row>
    <v-col>
      <compliance-grid />
    </v-col>
  </v-row>
</template>

<script>
import ComplianceGrid from '@/components/ComplianceGrid'

export default {
  name: 'Compliance',
  components: {
    'compliance-grid': ComplianceGrid,
  }
}
</script>
